import type { FunctionComponent, MouseEvent, ReactNode } from "react";

import type { Props as BaseLinkProps } from "scmp-app/components/common/base-link";

import { StyledButton, StyledLinkButton, StyledOffsetContainer } from "./styles";

type BaseProps = {
  backgroundColor?: string;
  children: ReactNode;
  className?: string;
  target?: string;
};

type NormalButtonProps = {
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  variant: "button";
} & BaseProps;

export type LinkButtonProps = {
  variant: "link";
} & BaseLinkProps &
  BaseProps;

type Props = LinkButtonProps | NormalButtonProps;

export const Button: FunctionComponent<Props> = props => {
  const { backgroundColor = "#fecc17", children, className, variant } = props;
  return (
    <StyledOffsetContainer backgroundColor={backgroundColor}>
      {variant === "button" ? (
        <StyledButton className={className} {...props}>
          {children}
        </StyledButton>
      ) : (
        <StyledLinkButton className={className} {...props}>
          {children}
        </StyledLinkButton>
      )}
    </StyledOffsetContainer>
  );
};

Button.displayName = "Button";
