import { css } from "@emotion/react";
import styled from "@emotion/styled";

export type ContainerProps = {
  $backgroundColor?: string;
  $borderColor: string;
  $borderRadius: string;
  $borderWidth: string;
  $childrenOffset: number;
  $offset: number;
};

const cssVariableNames: Record<keyof ContainerProps, string> = {
  $backgroundColor: "--offset-container-border-background-color",
  $borderColor: "--offset-container-border-color",
  $borderRadius: "--offset-container-border-radius",
  $borderWidth: "--offset-container-border-width",
  $childrenOffset: "--offset-container-children-offset",
  $offset: "--offset-container-offset",
};

export const setOffsetContainerCssVariables = (props: Partial<ContainerProps>) => css`
  ${Object.entries(props).map(([key, value]) => {
    const name = cssVariableNames[key as keyof ContainerProps];
    if (!name) return;
    return `${name}: ${value};`;
  })}
`;

export const ChildrenContainer = styled.div`
  position: relative;
`;

export const Container = styled.div<ContainerProps>`
  ${props => setOffsetContainerCssVariables(props)}

  position: relative;

  display: inline-block;

  margin-block-end: ${props => props.$offset}px;

  border-radius: var(${cssVariableNames.$borderRadius});

  background-color: #ffffff;

  &::before,
  &::after {
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;
    content: "";

    inline-size: 100%;
    block-size: 100%;

    border-radius: var(${cssVariableNames.$borderRadius});
  }

  &::before {
    inset-block-start: calc(var(${cssVariableNames.$offset}) * 1px);
    inset-inline-start: calc(var(${cssVariableNames.$offset}) * -1px);

    background-color: var(${cssVariableNames.$backgroundColor});
  }

  &::after {
    border: var(${cssVariableNames.$borderWidth}) var(${cssVariableNames.$borderColor}) solid;

    pointer-events: none;
  }

  ${props =>
    props.$childrenOffset &&
    css`
      ${ChildrenContainer} {
        inset-block-start: calc(var(${cssVariableNames.$childrenOffset}) * 1px);
        inset-inline-start: calc(var(${cssVariableNames.$childrenOffset}) * -1px);
      }
    `}
`;
